import { useContext } from "react";
import {
    Box,
    HStack,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button,
    SimpleGrid
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { InboundSalesContext } from "../InboundSales";
export { Head } from "../seo";

function PriceWrapper({ children }) {
    return (
        <Box
            mb={4}
            shadow="base"
            borderWidth="1px"
            alignSelf={{ base: 'center', lg: 'flex-start' }}
            borderColor={useColorModeValue('gray.200', 'gray.500')}
            borderRadius={'xl'}>
            {children}
        </Box>
    );
}

export default function Pricing() {
    const { onOpen } = useContext(InboundSalesContext);
    return (
        <Box py={12} maxW={'5xl'} mx="auto">
            <VStack spacing={2} textAlign="center">
                <Heading as="h1" fontSize="4xl">
                    Pricing
                </Heading>
                <Text fontSize="lg" color={'gray.500'}>
                    Contact us for a customized quote.
                </Text>
            </VStack>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} py={12}>
                <PriceWrapper>
                    <Box position="relative">
                        <Box
                            position="absolute"
                            top="-16px"
                            left="50%"
                            style={{ transform: 'translate(-50%)' }}>
                            <Text
                                textTransform="uppercase"
                                bg={useColorModeValue('brand.500', 'brand.500')}
                                px={3}
                                py={1}
                                color={useColorModeValue('gray.900', 'gray.300')}
                                fontSize="sm"
                                fontWeight="600"
                                rounded="xl">
                                Start Today
                            </Text>
                        </Box>
                        <Box py={4} px={8}>
                            <Text fontWeight="700" fontSize="3xl" textAlign="center">
                                Website
                            </Text>
                            <Text fontWeight="400" color="gray.500" textAlign="center">30 day free trial</Text>
                            <HStack justifyContent="center">
                                <Text fontSize="3xl" fontWeight="600">
                                    $
                                </Text>
                                <Text fontSize="5xl" fontWeight="800">
                                    49
                                </Text>
                                <Text fontSize="3xl" color="gray.500">
                                    /month
                                </Text>
                            </HStack>
                        </Box>
                        <VStack
                            bg={useColorModeValue('gray.50', 'gray.700')}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={8}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="gold.300" />
                                    Branded digital store front.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="gold.300" />
                                    Custom getparkify.com domain.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="gold.300" />
                                    Work with your PARCS and online sales channels.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="gold.300" />
                                    Local Business listing on Google Maps.
                                </ListItem>
                            </List>
                            <Box w="80%" pt={7}>
                                <Button
                                    w="full"
                                    colorScheme="brand"
                                    textColor="black"
                                    onClick={onOpen}
                                >
                                    Start Today
                                </Button>
                            </Box>
                        </VStack>
                    </Box>
                </PriceWrapper>
                <PriceWrapper >
                    <Box position="relative">
                        <Box
                            position="absolute"
                            top="-16px"
                            left="50%"
                            style={{ transform: 'translate(-50%)' }}>
                            <Text
                                textTransform="uppercase"
                                bg={useColorModeValue('gold.500', 'gold.500')}
                                px={3}
                                py={1}
                                color={useColorModeValue('gray.900', 'gray.300')}
                                fontSize="sm"
                                fontWeight="600"
                                rounded="xl">
                                Coming soon
                            </Text>
                        </Box>
                        <Box py={4} px={8}>
                            <Text fontWeight="700" fontSize="3xl" textAlign="center">
                                Online Sales
                            </Text>
                            <Text fontWeight="400" color="gray.500" textAlign="center">billed annually, per location</Text>
                            <HStack justifyContent="center">
                                <Text fontSize="3xl" fontWeight="600">
                                    $
                                </Text>
                                <Text fontSize="5xl" fontWeight="800">
                                    99
                                </Text>
                                <Text fontSize="3xl" color="gray.500">
                                    /month
                                </Text>
                            </HStack>
                        </Box>
                        <VStack
                            bg={useColorModeValue('gray.50', 'gray.700')}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={8}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="gold.300" />
                                    All benefits from "Website" plans.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="gold.300" />
                                    Lower processing fees than marketplaces.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="gold.300" />
                                    Create and manage custom parking products.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="gold.300" />
                                    Integration with your PARCS.
                                </ListItem>
                            </List>
                            <Box w="80%" pt={7}>
                                <Button
                                    w="full"
                                    variant="outline"
                                    onClick={onOpen}
                                >
                                    Contact Us
                                </Button>
                            </Box>
                        </VStack>
                    </Box>
                </PriceWrapper>
                <PriceWrapper>
                    <Box position="relative">
                        <Box
                            position="absolute"
                            top="-16px"
                            left="50%"
                            style={{ transform: 'translate(-50%)' }}>
                            <Text
                                textTransform="uppercase"
                                bg={useColorModeValue('gold.500', 'gold.500')}
                                px={3}
                                py={1}
                                color={useColorModeValue('gray.900', 'gray.300')}
                                fontSize="sm"
                                fontWeight="600"
                                rounded="xl">
                                Coming soon
                            </Text>
                        </Box>
                        <Box py={4} px={8}>
                            <Text fontWeight="700" fontSize="3xl" textAlign="center">
                                Portfolio
                            </Text>
                            <Text fontWeight="400" color="gray.500" textAlign="center">billed annually, starting at</Text>
                            <HStack justifyContent="center">
                                <Text fontSize="3xl" fontWeight="600">
                                    $
                                </Text>
                                <Text fontSize="5xl" fontWeight="800">
                                    999
                                </Text>
                                <Text fontSize="3xl" color="gray.500">
                                    /month
                                </Text>
                            </HStack>
                        </Box>
                    </Box>
                    <VStack
                        bg={useColorModeValue('gray.50', 'gray.700')}
                        py={4}
                        borderBottomRadius={'xl'}>
                        <List spacing={3} textAlign="start" px={8}>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="gold.300" />
                                All benefits from "Online Sales" plans.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="gold.300" />
                                Track occupancy rates, revenue, and other metrics across all locations in your portfolio.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="gold.300" />
                                Unlimited custom dashboards.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="gold.300" />
                                Organization management access control features.
                            </ListItem>
                        </List>
                        <Box w="80%" pt={7}>
                            <Button
                                w="full"
                                variant="outline"
                                onClick={onOpen}
                            >
                                Contact us
                            </Button>
                        </Box>
                    </VStack>
                </PriceWrapper>
            </SimpleGrid>
        </Box >
    );
}